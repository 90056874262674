import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import GatsbyImage from "gatsby-image";
import { Layout, SEO } from "../../../components/structure";
import {
  Content,
  Hero,
  CallToAction,
  Button,
  PlatformRevenueContainer,
  IconBlock,
} from "../../../components/blocks";

import styled from "styled-components";
import { mq, font, brand } from "../../../styles";

import english from "../../../../content/translations/en-CA/about.json";
import french from "../../../../content/translations/fr-CA/about.json";

const IndexPage = () => {
  let lang = english;
  const data = useStaticQuery(graphql`
    query {
      allHands: file(base: { eq: "aboutAllHands.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 400, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      mobileallHands: file(base: { eq: "aboutAllHands.jpg" }) {
        childImageSharp {
          fixed(width: 1000, height: 300, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      PlusgradeTimeline2: file(base: { eq: "PlusgradeTimeline2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1450, maxHeight: 350, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      heroImg: file(base: { eq: "Plusgrade-Hero-Banner-About.jpg" }) {
        ...FullHeroBackground
      }
      rightArrowIcon: file(base: { eq: "arrow-right-blue.png" }) {
        ...ButtonIcon
      }
      whiterightArrowIcon: file(base: { eq: "arrow-right.png" }) {
        ...ButtonIcon
      }
      aboutUsBackground: file(base: { eq: "GettyImages-1044786902.jpg" }) {
        childImageSharp {
          fixed(width: 1100, height: 700, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      pointsLogo: file(base: { eq: "Points.png" }) {
        childImageSharp {
          fixed(width: 145, height: 100, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      upstayLogo: file(base: { eq: "Upstay.png" }) {
        childImageSharp {
          fixed(width: 185, height: 85, webpQuality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);
  const PlatformRevenueContainer = styled.div`
    ${font.imports.sherika}
    width: 100%;
    height: auto;
    min-height: 570px;
    margin: auto;
    position: relative;
    @media screen and ${mq.maxSm} {
      min-height: 700px;
    }
    @media screen and (max-width: 700px) {
      min-height: 720px;
    }
    @media screen and (max-width: 630px) {
      min-height: 765px;
    }
    @media screen and (max-width: 500px) {
      min-height: 800px;
    }
    @media screen and (max-width: 450px) {
      min-height: 765px;
    }
    @media screen and (max-width: 415px) {
      min-height: 790px;
    }
    @media screen and (max-width: 381px) {
      min-height: 800px;
    }
    @media screen and (max-width: 350px) {
      min-height: 825px;
    }
    @media screen and (max-width: 345px) {
      min-height: 835px;
    }
    @media screen and (max-width: 330px) {
      min-height: 855px;
    }
    .background-con {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      .aboutBg.gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
        position: absolute !important;
        filter: grayscale(1) contrast(1.2);
      }
      &:after {
        content: "";
        position: absolute;
        z-index: 350;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          0deg,
          rgba(0, 4, 20, 0.65),
          rgba(0, 4, 20, 0.65)
        );
      }
    }

    .content-con {
      position: relative;
      z-index: 400;
      width: 95%;
      max-width: 1100px;
      margin: auto;
      height: 100%;
      padding: 50px 0;
      color: #fff;
      @media screen and ${mq.maxMd} {
        padding: 50px 0;
        text-align: center;
        width: 90%;
      }
      @media screen and ${mq.maxSm} {
        padding: 40px 0 20px;
      }
      h3 {
        font-size: 36px;
        color: #fff;
        font-weight: 600;
        text-shadow: 0 0 40px ${brand.colors.pg_primary_dark};
        @media screen and ${mq.maxSm} {
          font-size: 30px;
        }
      }

      .icon-content-con {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        position: relative;
        top: 30px;
        margin-bottom: 100px;
        @media screen and ${mq.maxSm} {
          display: block;
          top: 0px;
          .points {
            span {
              width: 100% !important;
              a {
                font-size: 16px;
              }
            }
          }
          .upstay {
            margin-top: 50px;
            .desktop-break {
              display: none;
            }
            span {
              width: 100% !important;
              a {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  `;
  return (
    <Layout>
      <SEO
        title={"À propos de | Plusgrade"}
        description={
          "Nous sommes Plusgrade, la puissance mondiale de revenus auxiliaires."
        }
        keywords={"travel, ancillary revenue, about us, "}
      />
      <Hero
        title={"À propos de"}
        subtitle={lang.hero.subtitle}
        image={data["heroImg"]}
        fullBG
      />
      <Content bgDarkBlue>
        <h3>
          Nous sommes une puissance mondiale en produits à revenus auxiliaires
        </h3>
        <p>
          Plusgrade apporte du bonheur aux voyageurs tout en générant des
          revenus auxiliaires mondialement pour les entreprises de l'industrie
          du voyage. Nous travaillons en partenariat avec plus de 200 compagnies
          aériennes, d'hôtellerie, de croisière, de transport ferroviaire et de
          services financiers afin de créer de nouvelles sources de revenus
          significatives et des expériences clients incroyables. Fondée en 2009,
          notre siège social se trouve à Montréal et nous avons des bureaux à
          Toronto, New York, Londres, Dubaï, Tel Aviv et Singapour.
        </p>
      </Content>
      <PlatformRevenueContainer>
        <div className="background-con">
          <GatsbyImage
            className={"aboutBg"}
            fixed={data["aboutUsBackground"].childImageSharp.fixed}
            imgStyle={{ objectFit: "cover", objectPosition: "center" }}
          />

          <div className="content-con">
            <h3>Croissance et expansion</h3>
            <div className="icon-content-con">
              <IconBlock
                icon={data["pointsLogo"]}
                aboutPage
                className={"points"}
                copy={"Points, une compagnie Plusgrade"}
              >
                <div style={{ marginBottom: "24px" }}>
                  En 2022, Plusgrade a acquis Points, le leader du commerce de
                  fidélisation, réunissant sous un même toit deux des plus
                  importantes sources de revenus auxiliaires afin de créer un
                  impact encore plus important pour les entreprises du secteur
                  du voyage dans le monde entier.
                </div>
                <Button
                  destination={"https://www.points.com"}
                  label={"En savoir plus sur Points"}
                  icon={data["whiterightArrowIcon"]}
                  rel={"noreferer"}
                  target={"_blank"}
                  orange
                  pill
                  whiteText
                  external
                />
              </IconBlock>
              <IconBlock
                icon={data["upstayLogo"]}
                aboutPage
                className={"upstay"}
                copy={"UpStay, maintenant la branche hospitalité de Plusgrade"}
              >
                <div style={{ marginBottom: "24px" }}>
                  UpStay fait désormais partie de l'activité hôtelière de
                  Plusgrade, améliorant ainsi nos solutions pour aider les
                  hôtels à maximiser leurs revenus et à offrir des expériences
                  exceptionnelles à leurs clients.
                </div>
                <br className="desktop-break" />
                <Button
                  destination={
                    "https://www.plusgrade.com/industries/hospitality/"
                  }
                  label={"En savoir plus sur l'hospitalité"}
                  icon={data["whiterightArrowIcon"]}
                  rel={"noreferer"}
                  target={"_blank"}
                  blue
                  pill
                  whiteText
                  external
                />
              </IconBlock>
            </div>
          </div>
        </div>
      </PlatformRevenueContainer>

      <Content>
        <Content aboutContentImageBlock className={"content-image-block"}>
          <div className="left">
            <h3>Notre direction</h3>
            <p>
              Rencontrez l'équipe de direction diversifiée et qualifiée qui nous
              guide dans notre mission, à savoir devenir le leader mondial des
              revenus auxiliaires.
            </p>
            <Button
              destination={"/fr/a-propos-de/equipe-de-direction"}
              label={"L'équipe de direction de Plusgrade"}
              icon={data["whiterightArrowIcon"]}
              orange
              pill
              whiteText
              aboutButton
            />
          </div>
          <div className="right">
            <GatsbyImage
              className={"aboutBg"}
              fixed={[
                data["mobileallHands"].childImageSharp.fixed,
                {
                  ...data.allHands.childImageSharp.fixed,
                  media: `(min-width: 991px)`,
                },
              ]}
              imgStyle={{ objectFit: "cover", objectPosition: "center" }}
            />
          </div>
        </Content>
      </Content>

      <CallToAction
        aboutUs
        title={"Joignez-vous à l’équipe"}
        subtitle={
          "Rejoignez notre équipe et aidez-nous à réinventer l'industrie du voyage."
        }
      >
        <Button
          destination={"/fr/carrieres/"}
          label={"Postes vacants"}
          icon={data["rightArrowIcon"]}
          white
          pill
          blueText
        />
      </CallToAction>
    </Layout>
  );
};

export default IndexPage;
